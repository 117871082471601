<template>
  <div>
    <div>
      <span class="q-inline-title">Service Flow</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class ButtonsUpload extends Vue {
}
</script>
